import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
  IonModal,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  eyeOutline,
} from "ionicons/icons";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OrderRequestRejectedProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getIncomingOrderAction: Function;
  isIncomingOrderSuccess: boolean;
  isIncomingOrderError: boolean;
  incomingorderData: any;
  readyOrderAction: Function;
}
const OrderRequestRejected: React.FC<OrderRequestRejectedProps> = ({
  setMenuAction,
  history,
  getIncomingOrderAction,
  isIncomingOrderSuccess,
  isIncomingOrderError,
  incomingorderData,
  readyOrderAction,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const [orderRejectedListData, setorderRejectedListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setselectedMenu] = useState([
    {
      menuDetails: { image_link: "", price: 0, menuName: "" },
      quantity: 0,
      subMenuDetails: { image_link: "", price: 0, subCategoryName: "" },
    },
  ]);
  const [productTotal, setproductTotal] = useState(0);
  const [orderDetails, setOrderDetails] = useState({
    orderId: "",
  });
  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  useIonViewWillEnter(() => {
    setMenuAction(true);
    getIncomingOrderAction(3);
  });

  useEffect(() => {
    if (isIncomingOrderSuccess) {
      setorderRejectedListData(incomingorderData.responses);
    }
  }, [isIncomingOrderSuccess]);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>Orders Rejected</h2>
            </IonLabel>

            <IonCard className="table-layout">
              <IonCardContent>
                {/* <IonRow>
                  <IonCol size="6" size-md="6">
                    Filter:
                    <select className="input-field">
                      <option>select</option>
                      <option>Breakfast</option>
                      <option>Lunch</option>
                      <option>Snacks</option>
                    </select>
                  </IonCol>

                  <IonCol size="6" size-md="6">
                    <input
                      type="text"
                      placeholder="Search by name, id, context"
                      className="input-field input-field-full float-right"
                    />
                  </IonCol>
                </IonRow> */}

                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="table-wrapper">
                      <table id="order-rejected">
                        <tbody>
                          <tr>
                            <th>Order ID</th>
                            <th>Menu Details</th>
                            <th>User</th>
                            {/* <th>Reason</th> */}
                            <th>Rejected Time</th>
                            <th>Payment Mode</th>
                          </tr>

                          {orderRejectedListData.length > 0 &&
                            orderRejectedListData.map(
                              (order: any, index: Number) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{order.orderId}</td>
                                      <td>
                                        <a
                                          onClick={() => {
                                            setIsOpen(true);
                                            setselectedMenu(
                                              order.orderMenuResponse
                                            );
                                            setOrderDetails(order);
                                            let productTotalCal = 0;
                                            order.orderMenuResponse?.forEach(
                                              (elm: any) => {
                                                productTotalCal +=
                                                  (elm.menuDetails
                                                    ? elm.menuDetails.price
                                                    : elm.subMenuDetails
                                                        .price) * elm.quantity;
                                              }
                                            );

                                            setproductTotal(productTotalCal);
                                          }}
                                        >
                                          View
                                        </a>
                                      </td>

                                      <td>{order.createdBy}</td>
                                      {/* <td>

                                                                            Reason here..
                                                                        </td> */}
                                      <td>
                                        {moment(order.createdOn).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                      {order.paymentMode == 1
                                      ? "PAID AT COUNTER"
                                      : order.paymentMode == 2
                                      ? "PAID ONLINE"
                                      : order.paymentMode == 3
                                      ? "PAID VIA SODEXO"
                                      : "-"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </div>
          <IonModal
            isOpen={isOpen}
            backdropDismiss={false}
            className="large-modal-custom print-bill-popup"
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonList>
                {selectedMenu.length > 0 && (
                  <div className="ion-padding">
                    <table className="menu-title-print">
                      <tr>
                        <th>VENTURE PROFESSIONALS</th>
                      </tr>
                      <tr>
                        <td> {moment().format("DD/MM/YYYY hh:mm:ss")}</td>
                      </tr>
                      <tr>
                        <td>VP - {orderDetails.orderId}</td>
                      </tr>
                    </table>

                    <table className="menu-item-print">
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>

                      {selectedMenu.map((menu, index) => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              {menu.menuDetails
                                ? menu.menuDetails.menuName
                                : menu.subMenuDetails.subCategoryName}
                            </td>
                            <td>
                              INR.{" "}
                              {menu.menuDetails
                                ? menu.menuDetails.price
                                : menu.subMenuDetails.price}
                            </td>
                            <td>{menu.quantity} unit</td>
                          </tr>
                        );
                      })}
                    </table>

                    <table className="menu-total-print">
                      <tr>
                        <th>Total</th>
                        <th></th>
                        <th>INR. {productTotal} </th>
                      </tr>

                      {/* <tr>
                      <td>Discount</td>
                      <td></td>
                      <td>INR. </td>
                    </tr>

                    <tr>
                      <td>Net payable</td>
                      <td></td>
                      <td>INR. </td>
                    </tr> */}
                    </table>

                    <table className="menu-title-print">
                      <tr>
                        <td>Thank you for ordering food.</td>
                      </tr>
                    </table>

                    {/* <table className="menu-btn-print">
                      <tr>
                        <td>
                          <button onClick={() => window.print()}> Print</button>
                        </td>
                      </tr>
                    </table> */}
                  </div>
                )}
              </IonList>
            </IonContent>
          </IonModal>
        </>
        {/* } */}

        {/* {isMobile &&
                    <>

                        <div className="listing-block">
                            <IonLabel className="pagename">
                                <h2>OrderRequestRejected</h2>
                            </IonLabel>

                            <IonCard className="display-card cart-view review-view mob-card-view">
                                <IonCardContent>
                                    <IonItem lines='none'>

                                    </IonItem>

                                </IonCardContent>
                            </IonCard>

                            <div className="mob-card-view-action">

                            </div>
                        </div>

                    </>
                } */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isIncomingOrderSuccess: state.counterheadData.isIncomingOrderSuccess,
  isIncomingOrderError: state.counterheadData.isIncomingOrderError,
  incomingorderData: state.counterheadData.incomingorderData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getIncomingOrderAction: (status: Number) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST,
        status: status,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderRequestRejected));
