import axiosInstance from '../../../components/config/axiosInstance';
export class CounterAPI {
  getIncomingOrders = (action) => {
    return axiosInstance
      .get('/api/v1/order/getOrders?status=' + action.status)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }

  acceptOrders = (data) => {
    return axiosInstance
      .post('/api/v1/order/acceptOrder', data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }


  deliverOrder = (data) => {
    return axiosInstance
      .post('/api/v1/order/deliverOrder', data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }

  rejectOrder = (data) => {
    return axiosInstance
      .post('/api/v1/order/rejectOrder', data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }


  readyOrder = (data) => {
    return axiosInstance
      .post('/api/v1/order/readyOrder', data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }
  
  getReviews = (data) => {
    return axiosInstance
      .get('/api/v1/order/getReviews', data)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }
 
  getReports = (data) => {
    return axiosInstance
      .post('/api/v1/report/getDailySaleReport',data )
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }


  getMenuWiseDaySaleReports = (data) => {
    return axiosInstance
      .post('/api/v1/report/getMenuWiseDaySaleReport',data )
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }


  getDashboardStats = (data) => {
    return axiosInstance
      .get('/api/v1/admin/vendorDashboard',data )
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }

  
   getLiveOrderStatus = () => {
    return axiosInstance
      .get('/api/v1/admin/getAccpetedReadyOrdersList')
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;
      })
  }
 
}