import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import Auth  from './constants'
import { CounterAPI } from './counterheadAPI';
import {
    getIncomingOrdersRequestSuccess,
    getIncomingOrdersRequestError,

    acceptOrderRequestSuccess,
    acceptOrderRequestError,
    COUNTERHEAD_ACTION_TYPES,

    readyOrderSuccess,
    readyOrderError,

    rejectOrderSuccess,
    rejectOrderError,


    deliverOrderSuccess,
    deliverOrderError,


    getReportsSuccess,
    getReportsError,


    getReviewsSuccess,
    getReviewsError,

    getMenuWiseReportsRequestSuccess,
    getMenuWiseReportsRequestError,
    getDashboardStatsRequest,
    getDashboardStatsRequestError,
    getDashboardStatsRequestSuccess,
      
    getLiveOrderStatusRequestSuccess,
    getLiveOrderStatusRequestError,
  } from './actions';

const counterAPI = new CounterAPI;


function* getIncomingOrdersFromApi(action) {
    try {
      // call the api
      const response = yield call(counterAPI.getIncomingOrders,action)
      if(response.status == 100) {
        // call the success action with data
        yield put(getIncomingOrdersRequestSuccess(response));
      } else {
        yield put(getIncomingOrdersRequestError(response));
      }
    } catch (e) {
        // call the error action with data
        yield put(getIncomingOrdersRequestError(e));
    }
}


function* acceptOrderFromApi(action) {

  try {
    // call the api
    const response = yield call(counterAPI.acceptOrders, action.payload)
    if(response.status == 100) {
      // call the success action with data
      yield put(acceptOrderRequestSuccess(response));
    } else {
      yield put(acceptOrderRequestError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(acceptOrderRequestError(e));
  }
}


function* rejectOrderFromApi(action) {
  try {
    // call the api
    const response = yield call(counterAPI.rejectOrder, {orderId:action.payload})
    if(response.status == 100) {
      // call the success action with data
      yield put(rejectOrderSuccess(response));
    } else {
      yield put(rejectOrderError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(rejectOrderError(e));
  }
}

function* readyOrderFromApi(action) {
  try {
    // call the api
    const response = yield call(counterAPI.readyOrder, {orderId:action.payload})

    if(response.status == 100) {
      // call the success action with data
      yield put(readyOrderSuccess(response));
    } else {

      yield put(readyOrderError(response));
    }
  } catch (e) {

    
      // call the error action with data
      yield put(readyOrderError(e));
  }
}

function* deliverOrderFromApi(action) {
  try {
    // call the api
    const response = yield call(counterAPI.deliverOrder, {orderId:action.payload})
    if(response.status == 100) {
      // call the success action with data
      yield put(deliverOrderSuccess(response));
    } else {
      yield put(deliverOrderError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(deliverOrderError(e));
  }
}

function* getReportsFromApi(action) {

  try {
    // call the api
    const response = yield call(counterAPI.getReports,action.payload)
    if(response.status == 100) {
      // call the success action with data

      yield put(getReportsSuccess(response.dateWiseSaleObjects));
    } else {
      yield put(getReportsError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(getReportsError(e));
  }
}


function* getReviewsFromApi() {
  try {
    // call the api
    const response = yield call(counterAPI.getReviews)
    if(response.status == 100) {
      // call the success action with data
      yield put(getReviewsSuccess(response));
    } else {
      yield put(getReviewsError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(getReviewsError(e));
  }
}


function* getMenuWiseReportsFromApi(action) {

  try {
    // call the api
    const response = yield call(counterAPI.getMenuWiseDaySaleReports,action.payload)
    if(response.status == 100) {
      // call the success action with data

      yield put(getMenuWiseReportsRequestSuccess(response.menuWiseDaySaleObjectList
));
    } else {
      yield put(getMenuWiseReportsRequestError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(getMenuWiseReportsRequestError(e));
  }
}


function* getDashboardStatsFromApi(action) {

  try {
    // call the api
    const response = yield call(counterAPI.getDashboardStats,action.payload)
    if(response.status == 100) {
      // call the success action with data

      yield put(getDashboardStatsRequestSuccess(response
));
    } else {
      yield put(getDashboardStatsRequestError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(getDashboardStatsRequestError(e));
  }
}


function* getLiveOrderStatusFromApi(action) {

  try {
    // call the api
    const response = yield call(counterAPI.getLiveOrderStatus,action.payload)
    if(response.status == 100) {
      // call the success action with data
      yield put(getLiveOrderStatusRequestSuccess(response));
    } else {
      yield put(getLiveOrderStatusRequestError(response));
    }
  } catch (e) {
      // call the error action with data
      yield put(getLiveOrderStatusRequestError(e));
  }
}



export default function* watchCounterhead() {
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST, getIncomingOrdersFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST, acceptOrderFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST, rejectOrderFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST, readyOrderFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST, deliverOrderFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST, getReportsFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST, getReviewsFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST, getMenuWiseReportsFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST, getDashboardStatsFromApi),
  yield takeEvery(COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST, getLiveOrderStatusFromApi)

}
