import { Auth } from './constants';
import { ACTION_TYPES } from "./actions";


const initialState = {
    userData: {},
    isRegisterSuccess: false,
    isRegisterError: false,
  
    loginData: {},
    isLoginSuccess: false,
    isLoginError: false,

    isOtpVerifiedSuccess:false,
    isOtpVerifiedError:false,

    isForgotPasswordSuccess:false,
    isForgotPasswordError:false,

    isResetPasswordSuccess:false,
    isResetPasswordError:false,

    isChangePasswordSuccess:false,
    isChangePasswordError:false,

}

 

export const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.REGISTER_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      userData: {},
      isRegisterSuccess:false,
      isRegisterError:false 
    };

    case ACTION_TYPES.REGISTER_REQUEST_SUCCESS:
      return { ...state, userData: action.payload,isRegisterSuccess:true,isRegisterError:false };

      case ACTION_TYPES.REGISTER_REQUEST_ERROR:

        return { ...state, userData: action.payload,isRegisterSuccess:false,isRegisterError:true };



  case ACTION_TYPES.LOGIN_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      loginData: {},
      isLoginSuccess:false,
      isLoginError:false 
    };

    case ACTION_TYPES.LOGIN_REQUEST_SUCCESS:
      return { ...state, loginData: action.payload,isLoginSuccess:true,isLoginError:false };

    case ACTION_TYPES.LOGIN_REQUEST_ERROR:

        return { ...state, loginData: action.payload,isLoginSuccess:false,isLoginError:true };


    case ACTION_TYPES.OTP_VERIFIED_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isOtpVerifiedSuccess:false,
      isOtpVerifiedError:false 
    };

    case ACTION_TYPES.OTP_VERIFIED_REQUEST_SUCCESS:
      return { ...state, isOtpVerifiedSuccess:true,isOtpVerifiedError:false };

    case ACTION_TYPES.OTP_VERIFIED_REQUEST_ERROR:
      return { ...state, isOtpVerifiedSuccess:false,isOtpVerifiedError:true };




    case ACTION_TYPES.FORGOT_PASSWORD_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isForgotPasswordSuccess:false,
      isForgotPasswordError:false 
    };

    case ACTION_TYPES.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return { ...state, isForgotPasswordSuccess:true,isForgotPasswordError:false };

    case ACTION_TYPES.FORGOT_PASSWORD_REQUEST_ERROR:

      return { ...state, isForgotPasswordSuccess:false,isForgotPasswordError:true };



    case ACTION_TYPES.RESET_PASSWORD_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isResetPasswordSuccess:false,
      isResetPasswordError:false 
    };

    case ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS:
      return { ...state, isResetPasswordSuccess:true,isResetPasswordError:false };

    case ACTION_TYPES.RESET_PASSWORD_REQUEST_ERROR:

      return { ...state, isResetPasswordSuccess:false,isResetPasswordError:true };







    default:
      return state
  }
}