import React, { useState, useEffect } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonGrid,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonSpinner,
  IonProgressBar,
  useIonAlert,
  useIonToast,
  IonLoading,
} from "@ionic/react";
import { ACTION_TYPES, setCartCount } from "../redux/user/orders/actions";
import { COUNTERHEAD_ACTION_TYPES } from "../redux/user/counterhead/actions";
import { DATA_ACTION_TYPES } from "../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import AuthHeader from "../components/AuthHeader";
import {
  checkmarkCircleOutline,
  timeOutline,
  volumeMediumOutline,
} from "ionicons/icons";
import { isDesktop, isMobile } from "react-device-detect";
import { useLocation } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}
interface MenuProps {
  menuName: "";
  subMenuDetails: [{ subCategoryName: "" }];
  availableQty: "";
  price: 0;
  mdId: 0;
  image_link: "";
  isIncludedInPackage: null;
}
interface OrderListProps {
  orderId: "";
  expectedTime: 0;
  status: 0;
  orderMenuResponse: [{ menuDetails: { menuName: ""; price: "" } }];
  subMenuDetails: [{ subCategoryName: "" }];
  isWaiting: false;
  timesUp: false;
  acceptedOn: null;
}

interface DashboardProps extends OwnProps, DispatchProps {
  getMenuListAction: Function;
  setMenuAction: Function;
  isMenuSuccess: boolean;
  isMenuError: boolean;
  menuData: Array<MenuProps>;
  getOrdersRequestAction: Function;
  orderData: Array<OrderListProps>;
  isOrdersSuccess: boolean;
  isOrdersError: boolean;
  repaycashAction: Function;
  repayonlinepaytmAction: Function;
  isRepayCashSuccess: boolean;
  isRepayCashError: boolean;

  isRepayOnlinePaytmSuccess: boolean;
  isRepayOnlinePaytmError: boolean;
  repayonlineorderedData: any;
}
const Dashboard: React.FC<DashboardProps> = ({
  getMenuListAction,
  setMenuAction,
  isMenuSuccess,
  isMenuError,
  menuData,
  getOrdersRequestAction,
  isOrdersSuccess,
  isOrdersError,
  orderData = [],
  repaycashAction,
  repayonlinepaytmAction,
  isRepayCashSuccess,
  isRepayCashError,
  history,
  isRepayOnlinePaytmSuccess,
  isRepayOnlinePaytmError,
  repayonlineorderedData,
}) => {
  // document.addEventListener("ionBackButton", (event) => {
  //    alert("hello");
  //   event.preventDefault();
  //   event.stopPropagation();
  //   false;
  // });

  const [messageToast] = useIonToast();

  const [presentAlert] = useIonAlert();
  const [showLoading, setShowLoading] = useState(false);

  const [playAudio, setPlayAudio] = useState();

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  let audio: any;

  const Playit = () => {
    var audio = new Audio("assets/sound/success-1-6297.mp3");
    audio.play();
  };

  useIonViewWillEnter(() => {
    setMenuAction(true);
    getMenuListAction({});
    getOrdersRequestAction();
    localStorage.removeItem("selectedOrderSummary");
  });

  const images = [
    { url: "assets/img/slider/slider1.jpg" },
    { url: "assets/img/slider/slider2.jpg" },
    { url: "assets/img/slider/slider3.jpg" },
  ];

  const [progress, setProgress] = useState(0);
  const [refreshActivated, setrefreshActivated] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 0.01);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  if (progress > 1) {
    setTimeout(() => {
      setProgress(0);
    }, 1000);
  }

  useEffect(() => {
    if (isOrdersSuccess) {
      // audio.play();

      let showAlert = false;
      orderData.forEach((element: any) => {
        if (element.acceptedOn != null) {
          let acceptedOn = new Date(element.acceptedOn);
          let deliveryTime = new Date(
            acceptedOn.getTime() + element.expectedTime * 60 * 1000
          );

          if (deliveryTime >= new Date()) {
            //This is case when order is in accepted state & getting ready
            element.isWaiting = true;
            element.timesUp = false;
          } else if (deliveryTime < new Date()) {
            //This case is when order is ready & order is expected to move to deliver
            element.timesUp = true;
          } else {
            element.isWaiting = false;
            element.timesUp = false;
          }
          if (element.status === 4 && !showAlert) {
            //Few orders are in ready state show a popup
            showAlert = true;
            // playAudio.play();
            Playit();

            presentAlert({
              header: "You order is ready. Collect it from counter.",
              cssClass: "custom-alert",
              buttons: [
                {
                  text: "OK",
                  role: "confirm",
                },
              ],
            });
          }
        }
      });
    }
  }, [isOrdersSuccess]);

  useEffect(() => {
    if (isRepayCashSuccess) {
      presentToast("top", "Cash Payment paid successfully.");
      window.location.reload();
    }
  }, [isRepayCashSuccess]);

  useEffect(() => {
    if (isRepayOnlinePaytmSuccess) {
      //load payment script & show popup
      showPaytm();
    }
  }, [isRepayOnlinePaytmSuccess]);

  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async function showPaytm() {
    setShowLoading(true);

    // const res = await loadScript(
    //   "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/Ventur07491403193113.js"
    // );

    const res = await loadScript(
      "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/Ventur07491403193113.js"
    );

    if (!res) {
      alert("Paytm SDK failed to load. Are you online?");
      return;
    }

    var config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId:
          `NYRA_PROD_Order_` +
          repayonlineorderedData.orderId /* update order id */,
        token: repayonlineorderedData.paytmTxnToken /* update token value */,
        tokenType: "TXN_TOKEN",
        amount: repayonlineorderedData.totalPrice /* update amount */,
      },
      handler: {
        notifyMerchant: function (eventName: any, data: any) {},
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            // alert("Loadedd.....");
            setShowLoading(false);

            // after successfully updating configuration, invoke JS Checkout
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error: any) {
            setShowLoading(false);
          });
      });
    }

    // placeOrder();
  }

  const handlePayments = (selectedOrder: any) => {
    presentAlert({
      header: "Select option to Pay",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: (data) => {
            if (data == 1) {
              //handle COD
              let updatedData = {
                orderId: selectedOrder.orderId,
                paymentMode: data,
              };

              repaycashAction(updatedData);
            } else if (data == 2) {
              let updatedData = {
                orderId: selectedOrder.orderId,
              };

              repayonlinepaytmAction(updatedData);
            }

            // acceptOrderAction(acceptData);
          },
        },
      ],

      inputs: [
        {
          label: "Pay at Counter",
          type: "radio",
          value: "1",
        },
        // {
        //   label: "Pay Online",
        //   type: "radio",
        //   value: "2",
        // },
      ],
    });
  };

  const navigateToOrderSummary = (selectedOrderSummary: any) => {
    console.log(selectedOrderSummary);
    localStorage.setItem(
      "selectedOrderSummary",
      JSON.stringify(selectedOrderSummary)
    );

    history.push("/orderSummary");
  };
  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Dashboard</h2>
              </IonLabel>

              <div className="banner-adv">
                <img alt="" src={"assets/img/advertisement.jpg"} />
              </div>

              {isOrdersSuccess && (
                <>
                  {orderData.length > 0 && (
                    <IonButton
                      className="get-status"
                      type="button"
                      onClick={() => {
                        getOrdersRequestAction();
                        setrefreshActivated(true);
                        setTimeout(() => {
                          setrefreshActivated(false);
                        }, 300000);
                      }}
                      disabled={refreshActivated}
                    >
                      Refresh Status Of Order(s)
                    </IonButton>
                  )}
                </>
              )}

              <IonList lines="none">
                {isOrdersSuccess && (
                  <>
                    {isOrdersSuccess &&
                      orderData.map((orders, index) => {
                        return (
                          <>
                            <IonLabel className="meal-name">
                              <h2>
                                Order Id : {orders.orderId}
                                <span>
                                  {" "}
                                  {orders.status != Number(12) ? (
                                    <>Order in progress</>
                                  ) : (
                                    <>Payment Pending</>
                                  )}
                                </span>
                              </h2>

                              {/* {orders.isWaiting == Boolean(true) && ( */}

                              {orders.status != Number(12) && (
                                <span className="timer-icon">
                                  <img src="assets/gif/timer.gif" />
                                  {/* <IonIcon src={timeOutline} size="large" /> */}
                                  &nbsp;{orders.expectedTime} min
                                </span>
                              )}
                              {/* )} */}

                              {/*{orders.acceptedOn != null &&
                                orders.timesUp != false && (
                                  <span>
                                    Hey, order should be ready . Please check at
                                    counter.
                                  </span>
                                )} */}
                            </IonLabel>

                            <IonCard className="display-card order-in-progress">
                              <IonProgressBar value={progress}></IonProgressBar>

                              <IonCardContent>
                                <IonItem>
                                  <IonGrid>
                                    <IonRow>
                                      {orders.status != Number(12) ? (
                                        <IonCol
                                          size="2"
                                          size-md="2"
                                          className="product-image-block"
                                        >
                                          <img
                                            alt=""
                                            src={`assets/img/art/food-process-05.jpg`}
                                          />
                                        </IonCol>
                                      ) : (
                                        <IonCol
                                          size="2"
                                          size-md="2"
                                          className="product-image-block"
                                        >
                                          <img
                                            alt=""
                                            src={`assets/img/art/food-process-02.jpg`}
                                          />
                                        </IonCol>
                                      )}

                                      <IonCol
                                        size="10"
                                        size-md="10"
                                        className="product-progress-block progress-flat"
                                      >
                                        <ul>
                                          {orders.status != Number(12) && (
                                            <>
                                              <li>
                                                <p>
                                                  {orders.status >= 1 ? (
                                                    <IonIcon
                                                      src={
                                                        checkmarkCircleOutline
                                                      }
                                                    />
                                                  ) : (
                                                    <IonSpinner name="lines-sharp-small"></IonSpinner>
                                                  )}

                                                  <span>Order placed</span>
                                                </p>
                                              </li>
                                              <li>
                                                <p>
                                                  {orders.status >= 2 ? (
                                                    <IonIcon
                                                      src={
                                                        checkmarkCircleOutline
                                                      }
                                                    />
                                                  ) : (
                                                    <IonSpinner name="lines-sharp-small"></IonSpinner>
                                                  )}

                                                  <span>Order accepted</span>
                                                </p>
                                              </li>
                                              <li>
                                                <p>
                                                  {orders.status >= 4 ? (
                                                    <IonIcon
                                                      src={
                                                        checkmarkCircleOutline
                                                      }
                                                    />
                                                  ) : (
                                                    <IonSpinner name="lines-sharp-small"></IonSpinner>
                                                  )}

                                                  <span>Order ready</span>
                                                </p>
                                              </li>

                                              <li
                                                onClick={() =>
                                                  navigateToOrderSummary(orders)
                                                }
                                              >
                                                <p>
                                                  <a>View Details</a>
                                                </p>
                                              </li>
                                            </>
                                          )}

                                          <li>
                                            {orders.status === Number(12) && (
                                              <>
                                                <IonButton
                                                  onClick={() => {
                                                    handlePayments(orders);
                                                  }}
                                                >
                                                  <IonIcon
                                                    src={checkmarkCircleOutline}
                                                  />{" "}
                                                  Proceed for Payments
                                                </IonButton>
                                                <small>
                                                  Complete your payments to
                                                  confirm your order
                                                </small>
                                              </>
                                            )}
                                          </li>
                                        </ul>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonItem>
                              </IonCardContent>
                            </IonCard>
                          </>
                        );
                      })}
                  </>
                )}
              </IonList>
              {isMenuSuccess && (
                <>
                  <IonGrid>
                    {menuData.length > 0 && (
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          Menu
                        </IonCol>
                      </IonRow>
                    )}
                    <IonRow>
                      <IonCol size="12" size-md="12">
                        {menuData.length > 0 &&
                          menuData.map((menu, index) => {
                            return (
                              <>
                                {menu.isIncludedInPackage == 1 && (
                                  <div className="menu-card">
                                    <img
                                      alt=""
                                      src={
                                        menu.image_link
                                          ? menu.image_link
                                          : "assets/img/menu/default.png"
                                      }
                                    />
                                    <h5>{menu.menuName}</h5>
                                    <a href="/menu">See more</a>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </>
              )}
            </div>
          </>
        )}
        {isMobile && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Dashboard</h2>
              </IonLabel>

              <div className="banner-adv">
                <img alt="" src={"assets/img/advertisement.jpg"} />
              </div>

              {isOrdersSuccess && (
                <>
                  {orderData.length > 0 && (
                    <IonButton
                      className="get-status"
                      type="button"
                      onClick={() => {
                        getOrdersRequestAction();
                        setrefreshActivated(true);
                        setTimeout(() => {
                          setrefreshActivated(false);
                        }, 300000);
                      }}
                      disabled={refreshActivated}
                    >
                      Refresh Status Of Order(s)
                    </IonButton>
                  )}
                </>
              )}

              <IonList lines="none">
                <IonCard className="display-card order-in-progress mobile-view">
                  <IonProgressBar value={progress}></IonProgressBar>

                  <IonCardContent>
                    <IonItem>
                      <IonGrid>
                        <IonRow>
                          <IonCol
                            size="12"
                            size-md="12"
                            className="product-image-block"
                          >
                            <img
                              alt=""
                              src={`assets/img/art/food-process-05.jpg`}
                            />
                            <hr />
                          </IonCol>

                          {isOrdersSuccess &&
                            orderData.map((orders, index) => {
                              return (
                                <>
                                  <IonCol
                                    size="12"
                                    size-md="12"
                                    className="product-progress-block progress-flat"
                                  >
                                    <IonLabel className="meal-name">
                                      <h2>
                                        Order Id : {orders.orderId}
                                        <span>
                                          {" "}
                                          {orders.status != Number(12) ? (
                                            <>Order in progress</>
                                          ) : (
                                            <>Payment Pending</>
                                          )}
                                        </span>
                                        {/* {orders.status} */}
                                      </h2>

                                      {/* {orders.isWaiting != false && ( */}
                                      {orders.status != Number(12) && (
                                        <span className="timer-icon">
                                          <img src="assets/gif/timer.gif" />
                                          {/* <IonIcon src={timeOutline} size="large" /> */}
                                          &nbsp;{orders.expectedTime} min
                                        </span>
                                      )}
                                      {/* )} */}
                                      {/*
                                      {orders.acceptedOn != null &&
                                        orders.timesUp != false && (
                                          <span>
                                            Hey, order should be ready . Please
                                            check at counter.
                                          </span>
                                        )} */}
                                    </IonLabel>

                                    {orders.status != Number(12) && (
                                      <ul>
                                        <>
                                          <li>
                                            <p>
                                              {orders.status >= 1 ? (
                                                <IonIcon
                                                  src={checkmarkCircleOutline}
                                                />
                                              ) : (
                                                <IonSpinner name="lines-sharp-small"></IonSpinner>
                                              )}

                                              <span>Order placed</span>
                                            </p>
                                          </li>
                                          <li>
                                            <p>
                                              {orders.status >= 2 ? (
                                                <IonIcon
                                                  src={checkmarkCircleOutline}
                                                />
                                              ) : (
                                                <IonSpinner name="lines-sharp-small"></IonSpinner>
                                              )}

                                              <span>Order accepted</span>
                                            </p>
                                          </li>
                                          <li>
                                            <p>
                                              {orders.status >= 4 ? (
                                                <IonIcon
                                                  src={checkmarkCircleOutline}
                                                />
                                              ) : (
                                                <IonSpinner name="lines-sharp-small"></IonSpinner>
                                              )}

                                              <span>Order ready</span>
                                            </p>
                                          </li>

                                          <li>
                                            <p>
                                              <a
                                                onClick={() =>
                                                  navigateToOrderSummary(orders)
                                                }
                                              >
                                                View Details
                                              </a>
                                            </p>
                                          </li>
                                        </>
                                      </ul>
                                    )}

                                    {orders.status === Number(12) && (
                                      <div className="order-action-block">
                                        <IonButton
                                          onClick={() => {
                                            handlePayments(orders);
                                          }}
                                        >
                                          <IonIcon
                                            src={checkmarkCircleOutline}
                                          />{" "}
                                          Proceed for Payments
                                        </IonButton>
                                        <br />
                                        <small>
                                          Complete your payments to confirm your
                                          order
                                        </small>
                                      </div>
                                    )}

                                    <hr />
                                  </IonCol>
                                </>
                              );
                            })}
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              </IonList>

              {isMenuSuccess && (
                <>
                  <IonGrid>
                    {menuData.length > 0 && (
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          Menu
                        </IonCol>
                      </IonRow>
                    )}
                    <IonRow>
                      <IonCol size="12" size-md="12">
                        {menuData.length > 0 &&
                          menuData.map((menu, index) => {
                            return (
                              <>
                                {menu.isIncludedInPackage == 1 && (
                                  <div className="menu-card">
                                    <img
                                      alt=""
                                      src={
                                        menu.image_link
                                          ? menu.image_link
                                          : "assets/img/menu/default.png"
                                      }
                                    />

                                    <h5>{menu.menuName}</h5>
                                    <a href="/menu">See more</a>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </>
              )}
            </div>
          </>
        )}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          // duration={5000}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  menuData: state.orders.menuData,
  isMenuSuccess: state.orders.isMenuSuccess,
  isMenuError: state.orders.isMenuError,

  isOrdersSuccess: state.orders.isOrdersSuccess,
  isOrdersError: state.orders.isOrdersError,
  orderData: state.orders.orderData,

  isRepayCashSuccess: state.orders.isRepayCashSuccess,
  isRepayCashError: state.orders.isRepayCashError,

  isPayOrderOnlineSuccess: state.orders.isPayOrderOnlineSuccess,
  isPayOrderOnlineError: state.orders.isPayOrderOnlineError,

  isRepayOnlinePaytmSuccess: state.orders.isRepayOnlinePaytmSuccess,
  isRepayOnlinePaytmError: state.orders.isRepayOnlinePaytmError,
  repayonlineorderedData: state.orders.repayonlineorderedData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getMenuListAction: ({}) =>
      dispatch({
        type: ACTION_TYPES.GET_MENU_REQUEST,
      }),
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getOrdersRequestAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_ORDERS_REQUEST,
      }),
    getDashboardStatsAction: () =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST,
      }),
    repaycashAction: (updatedData: any) =>
      dispatch({
        type: ACTION_TYPES.REPAY_CASH_REQUEST,
        payload: updatedData,
      }),
    repayonlinepaytmAction: (updatedData: any) =>
      dispatch({
        type: ACTION_TYPES.REPAY_ONLINE_PAYTM_REQUEST,
        payload: updatedData,
      }),
    payOrderOnlineAction: (orderDetails: boolean) =>
      dispatch({
        type: ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST,
        payload: orderDetails,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
