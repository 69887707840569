import React, { useEffect, useRef, useState } from "react";
import {
  useIonViewWillEnter,
  IonDatetime,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import moment from "moment";
import BarGraph from "../../components/Charts/BarGraph/BarGraph";
import LineChart from "../../components/Charts/LineChart/LineChart";
import { useDownloadExcel } from "react-export-table-to-excel";

interface OwnProps {}

interface DispatchProps {}

interface ReportsProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getReportsAction: Function;
  isgetReportsSuccess: boolean;
  isgetReportsError: boolean;
  reportsData: [];

  getMenuWiseReportsAction: Function;
  isgetMenuWiseDaySaleReportsSuccess: boolean;
  isgetMenuWiseDaySaleReportsError: boolean;
  menuWiseReportData: [];
}
const Reports: React.FC<ReportsProps> = ({
  setMenuAction,
  getReportsAction,
  isgetReportsSuccess,
  isgetReportsError,
  reportsData,

  getMenuWiseReportsAction,
  isgetMenuWiseDaySaleReportsSuccess,
  isgetMenuWiseDaySaleReportsError,
  menuWiseReportData,
}) => {
  const tableRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
  });

  useIonViewWillEnter(() => {
    setMenuAction(true);
    getReportsAction(data);
  });
  useEffect(() => {
    if (isgetReportsSuccess) {
    }
  }, [isgetReportsSuccess]);

  useEffect(() => {
    if (isgetMenuWiseDaySaleReportsSuccess) {
    }
  }, [isgetMenuWiseDaySaleReportsSuccess]);

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    let value = val || valueEvent;
    let name = nameEvent;
    if (name == "toDate") {
      value = moment(value).add(1, "days").format("YYYY-MM-DD");
    }
    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleDateChange = (e: any) => {
    onChange(e.target.value, e.target.name);
  };
  const handleReports = () => {
    getReportsAction({
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
  };

  const handleMenuWise = (selectedReport: any) => {
    getMenuWiseReportsAction({ fromDate: selectedReport.createdOn });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* 
                {isDesktop &&
                    <> */}

        <div className="listing-block">
          <IonLabel className="pagename">
            <h2>Reports</h2>
          </IonLabel>

          <IonCard className="table-layout">
            <IonCardContent>
              {/* <IonRow>
                <IonCol size="6" size-md="6">
                  <picture>
                    <img src="assets/img/graph-1.jpg" />
                  </picture>
                </IonCol>

                <IonCol size="6" size-md="6">
                  <picture>
                    <img src="assets/img/graph-2.jpg" />
                  </picture>
                </IonCol>
              </IonRow> */}

              <IonRow>
                <IonCol size="4" size-md="4">
                  <label style={{ margin: ".4rem 0" }} htmlFor="start">
                    Start date:
                  </label>

                  <input
                    type="date"
                    id="from"
                    name="fromDate"
                    onChange={(e) => handleDateChange(e)}
                    // min="2018-01-01" max="2018-12-31"
                  />
                </IonCol>

                <IonCol size="4" size-md="4">
                  <label htmlFor="start" style={{ margin: ".4rem 0" }}>
                    End date:
                  </label>
                  <input
                    type="date"
                    id="to"
                    name="toDate"
                    onChange={(e) => handleDateChange(e)}
                  />
                </IonCol>

                <IonCol size="4" size-md="4">
                  <IonButton onClick={() => handleReports()}>
                    Get Reports
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="table-wrapper">
                    {reportsData.length > 0 && (
                      <div>
                        {/* <button onClick={onDownload}> Export excel </button> */}

                        <table ref={tableRef}>
                          <tr>
                            <th>Delivered Count</th>
                            <th>Order Created</th>
                            <th>Total Transactions</th>
                            <th></th>
                          </tr>

                          {reportsData.map((report: any, index: Number) => {
                            return (
                              <>
                                <tr>
                                  <td>{report.deliveredCount}</td>
                                  <td>{report.createdOn}</td>
                                  <td>{report.totalPrice}</td>
                                  <th>
                                    <a
                                      onClick={() => {
                                        setIsOpen(true);
                                        handleMenuWise(report);
                                      }}
                                    >
                                      View
                                    </a>
                                  </th>
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    )}

                    {/* <table>
                      <tr>
                        <th>Date</th>
                        <th>Menu</th>
                        <th>Orders Received</th>
                        <th>Orders Delivered</th>
                        <th>Orders Declined</th>
                        <th>Sales (in INR)</th>
                      </tr>
                      <tr>
                        <td>11/11/2022</td>
                        <td>Breakfast</td>
                        <td>380 units</td>
                        <td>365 units</td>
                        <td>15 units</td>
                        <td>INR. 15,750</td>
                      </tr>
                      <tr>
                        <td>11/11/2022</td>
                        <td>Lunch</td>
                        <td>420 units</td>
                        <td>415 units</td>
                        <td>5 units</td>
                        <td>INR. 25,000</td>
                      </tr>
                      <tr>
                        <td>11/11/2022</td>
                        <td>Snacks</td>
                        <td>380 units</td>
                        <td>380 units</td>
                        <td>0 units</td>
                        <td>INR. 14,750</td>
                      </tr>
                      <tr>
                        <td>12/11/2022</td>
                        <td>Breakfast</td>
                        <td>400 units</td>
                        <td>390 units</td>
                        <td>10 units</td>
                        <td>INR. 17,500</td>
                      </tr>
                      <tr>
                        <td>12/11/2022</td>
                        <td>Lunch</td>
                        <td>425 units</td>
                        <td>425 units</td>
                        <td>0 units</td>
                        <td>INR. 30,000</td>
                      </tr>
                      <tr>
                        <td>12/11/2022</td>
                        <td>Snacks</td>
                        <td>370 units</td>
                        <td>370 units</td>
                        <td>0 units</td>
                        <td>INR. 14,250</td>
                      </tr> 
                    </table>*/}
                  </div>
                </IonCol>
              </IonRow>
            </IonCardContent>

            <IonModal
              isOpen={isOpen}
              backdropDismiss={false}
              className="large-modal-custom"
            >
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton onClick={() => setIsOpen(false)}>
                      Close
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <BarGraph menuWiseReportData={menuWiseReportData}></BarGraph>

                <div className="table-layout">
                  <div className="table-wrapper">
                    {reportsData.length > 0 && (
                      <table>
                        <tr>
                          <th>Menu Name</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </tr>

                        {menuWiseReportData.map((menu: any, index: Number) => {
                          return (
                            <>
                              <tr>
                                <td>{menu.menuName}</td>
                                <td>{menu.quantity}</td>
                                <td>{menu.totalPrice}</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    )}
                  </div>
                </div>
              </IonContent>
            </IonModal>
          </IonCard>
        </div>

        {/* </>
                } */}

        {/* {isMobile &&
                    <>

                        <div className="listing-block">
                            <IonLabel className="pagename">
                                <h2>Reports</h2>
                            </IonLabel>

                            <IonCard className="display-card cart-view review-view mob-card-view">
                                <IonCardContent>
                                    <IonItem lines='none'>

                                    </IonItem>

                                </IonCardContent>
                            </IonCard>

                            <div className="mob-card-view-action">

                            </div>
                        </div>

                    </>
                } */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isgetReportsSuccess: state.counterheadData.isgetReportsSuccess,
  isgetReportsError: state.counterheadData.isgetReportsError,
  reportsData: state.counterheadData.reportsData,

  isgetMenuWiseDaySaleReportsSuccess:
    state.counterheadData.isgetMenuWiseDaySaleReportsSuccess,
  isgetMenuWiseDaySaleReportsError:
    state.counterheadData.isgetMenuWiseDaySaleReportsError,
  menuWiseReportData: state.counterheadData.menuWiseReportData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getReportsAction: (dates: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST,
        payload: dates,
      }),

    getMenuWiseReportsAction: (fromDate: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST,
        payload: fromDate,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
