import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Login.scss";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import { connect } from "react-redux";
import { ACTION_TYPES } from "../redux/auth/actions";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface ResetPasswordProps extends OwnProps, DispatchProps {
  isResetPasswordSuccess: boolean;
  isResetPasswordError: boolean;
  resetPasswordAction: Function;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  history,
  isResetPasswordSuccess,
  isResetPasswordError,
  resetPasswordAction,
}) => {
  const [messageToast] = useIonToast();

  const [data, setData] = useState({ password: "", mobile: "" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [otpError, setPasswordError] = useState(false);
  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  useEffect(() => {
    let registeredMobile = JSON.parse(localStorage.getItem("registeredMobile")!)
      ? JSON.parse(localStorage.getItem("registeredMobile")!)
      : "";

    setData((prevValue) => ({
      ...prevValue,
      mobile: registeredMobile,
    }));
  }, []);

  useEffect(() => {
    if (isResetPasswordSuccess) {
      // if() {

      //   presentToast("top", "User authenticated successfully.");
      //   history.push("/signin", { direction: "none" });
      // } else {
      presentToast("top", "Password reset successfully.");
      history.push("/signin", { direction: "none" });
      // }
    }
  }, [isResetPasswordSuccess]);

  useEffect(() => {
    if (isResetPasswordError) {
      presentToast("top", "Some issue occurred ,Please try again later.");
    }
  }, [isResetPasswordError]);

  const submit = async (e: React.FormEvent) => {
    setFormSubmitted(true);

    if (!data["password"] || !data["mobile"]) {
      setPasswordError(true);
    } else {
      resetPasswordAction(data);
    }
  };

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    const value = val || valueEvent;
    const name = nameEvent;

    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleChange = (e: any) => {
    onChange(e.target.value, e.target.name);
  };

  return (
    <IonPage id="login-page">
      <IonContent className="sticky-container">
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form className="small-form" noValidate>
          <h3>Reset password</h3>

          <IonList>
            <IonItem>
              <IonLabel position="floating">Mobile No.</IonLabel>
              <IonInput
                name="mobile"
                type="text"
                value={data["mobile"]}
                onIonChange={(e) => {
                  handleChange(e);
                }}
                disabled={true}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="floating">New Password</IonLabel>
              <IonInput
                name="password"
                type="text"
                value={data["password"]}
                spellCheck={false}
                autocapitalize="off"
                required
                onIonChange={(e) => {
                  handleChange(e);
                }}
              ></IonInput>
            </IonItem>

            {/* <IonItem>
              <IonLabel position="floating">Confirm Password</IonLabel>
              <IonInput
                name="confirmPassword"
                type="text"
                value={data["confirmPassword"]}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  handleChange(e);
                }}
              ></IonInput>
            </IonItem> */}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="button" expand="block" onClick={submit}>
                Update password
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isResetPasswordSuccess: state.auth.isResetPasswordSuccess,
  isResetPasswordError: state.auth.isResetPasswordError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    resetPasswordAction: (data: {}) =>
      dispatch({
        type: ACTION_TYPES.RESET_PASSWORD_REQUEST,
        payload: data,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
