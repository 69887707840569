import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  personCircle,
} from "ionicons/icons";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import {
  notificationsCircleOutline,
  ellipsisVerticalSharp,
  notificationsOutline,
  cartOutline,
  logOutOutline,
} from "ionicons/icons";
import { BrowserView, MobileView } from "react-device-detect";
import moment from "moment";
interface OwnProps extends RouteComponentProps {}
interface DispatchProps {}
interface OrderStatusProps extends OwnProps, DispatchProps {
  getLiveOrderStatusRequest: Function;
  isLiveOrderStatusSuccess: boolean;
  isLiveOrderStatusError: boolean;
  orderStatusData: { acceptedOrders: []; readyOrders: [] };
}
const OrderStatus: React.FC<OrderStatusProps> = ({
  getLiveOrderStatusRequest,
  isLiveOrderStatusSuccess,
  isLiveOrderStatusError,
  orderStatusData,
  history,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const [orderData, setorderData] = useState({ process: [{}], ready: [{}] });
  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  const [userDetails, setuserDetails] = useState({
    firstname: "",
    lastname: "",
    role: "",
    userId: 0,
  });
  let count = 1;
  useEffect(() => {
    if (localStorage.getItem("userDetails") != null) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")!);
      setuserDetails(userDetails);
    }
  }, []);
  const logout = () => {
    history.push("/signin");
    localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    // console.log(
    //   "orderData...................",
    //   isLiveOrderStatusSuccess,
    //   orderStatusData
    // );
    if (isLiveOrderStatusSuccess) {
      // setorderData({
      //   process:
      //     orderStatusData["acceptedOrders"].length > 0 &&
      //     orderStatusData["acceptedOrders"],
      //   ready:
      //     orderStatusData["readyOrders"].length > 0 &&
      //     orderStatusData["readyOrders"],
      // });
      // console.log(orderData);
    }
  }, [isLiveOrderStatusSuccess]);
  useEffect(() => {
    getLiveOrderStatusRequest();
    // console.log(new Date())
    setInterval(repeatCall, 30000); 
  }, []);

  const repeatCall = () => {
    if (moment().hour() >= 8 && moment().hour() <= 9 ||
    moment().hour() >= 12 && moment().hour() <= 14 ||
    moment().hour() >= 16 && moment().hour() <= 18
    ) { 
      getLiveOrderStatusRequest();
    }
  }
  return (
    <IonPage>
      <IonHeader>
        <BrowserView>
          <IonToolbar>
            <div className="menu-listing-nav">
              <picture className="app-header-logo">
                <img src="assets/img/logo.png" alt="Ionic logo" />
              </picture>
              <div className="col-right">
                <span className="username">
                  {userDetails.firstname} {userDetails.lastname}
                </span>
                <br />
                <span className="userid">User ID: VP{userDetails.userId}</span>
                <span className="dropdown">
                  <IonButton
                    fill="clear"
                    id="click-trigger"
                    onClick={() => logout()}
                  >
                    <IonIcon src={logOutOutline} />
                  </IonButton>
                </span>
              </div>
            </div>
          </IonToolbar>
        </BrowserView>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="order-live-block">
          <div className="half-block in-progress">
            <div>
              <img alt="" src={`assets/img/art/order-in-progress.png`} />
              <ul>
                {isLiveOrderStatusSuccess &&
                  orderStatusData["acceptedOrders"].length > 0 &&
                  orderStatusData["acceptedOrders"].map((item: any) => {
                    return <li>{item}</li>;
                  })}
              </ul>
            </div>
          </div>
          <div className="half-block is-ready">
            <div>
              <img alt="" src={`assets/img/art/order-is-ready.png`} />
              <ul>
                {isLiveOrderStatusSuccess &&
                  orderStatusData["readyOrders"].length > 0 &&
                  orderStatusData["readyOrders"].map((item: any) => {
                    return <li>{item}</li>;
                  })}
              </ul>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
const mapStateToProps = (state: any) => ({
  isLiveOrderStatusSuccess: state.counterheadData.isLiveOrderStatusSuccess,
  isLiveOrderStatusError: state.counterheadData.isLiveOrderStatusError,
  orderStatusData: state.counterheadData.orderStatusData,
});
function mapDispatchToProps(dispatch: any) {
  return {
    getLiveOrderStatusRequest: () =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST,
      }),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderStatus));
