import { ACTION_TYPES } from "./actions";


const initialState = {
    ordersData: {},
    isOrdersSuccess: false,
    isOrdersError: false,

    orderHistoryData: {},
    isOrderHistorySuccess:false,
    isOrderHistoryError:false, 

    menuData: {},
    isMenuSuccess:false,
    isMenuError:false,
    
    
    orderedData: {},
    isSetOrderSuccess: false,
    isSetOrderError: false,

    isCartAddedSuccess:false,
    isCartAddedError:false,


    updatedcartItem: {},
    isCartUpdatedSuccess:false,
    isCartUpdatedError:false,


    isCartDeletedSuccess:false,
    isCartDeletedError:false,
    cartCount : 0,

    cartItem: {},
    isCartGetError:false,
    isCartGetSuccess:false,

    isCartDeletedSuccess:false,
    isCartDeletedError:false,

    isReviewAddedSuccess: false,
    isReviewAddedError: false,

    isSubMenuSuccess: false,
    isSubMenuError: false,


    isMenuUpdatedSuccess: false,
    isMenuUpdatedError: false,


    isPayOrderOnlineSuccess: false,
    isPayOrderOnlineError: false,


    isRepayCashSuccess: false,
    isRepayCashError: false,

    isRepayOnlinePaytmSuccess: false,
    isRepayOnlinePaytmError: false,

    isPayOrderOnlineSodexoSuccess: false,
    isPayOrderOnlineSodexoError: false,

    isSodexoCardAddedSuccess: false,
    isSodexoCardAddedError: false,


    isSodexoCardDeletedSuccess: false,
    isSodexoCardDeletedError: false,

    sodexoCardDetails:{},
    isSodexoCardGetSuccess: false,
    isSodexoCardGetError: false,



}

 

export const ordersReducer = (state = initialState, action) => {
  console.log(action.payload)
  switch (action.type) {
    case ACTION_TYPES.GET_MENU_REQUEST:
      // here we catch our payload data and pass it into the state of the store
      return { ...state, 
        menuData: {},
        isMenuSuccess:false,
        isMenuError:false 
      };

  case ACTION_TYPES.GET_MENU_REQUEST_SUCCESS:
    
    return { ...state, menuData: action.payload.menuDetails,isMenuSuccess:true,isMenuError:false };

  case ACTION_TYPES.GET_MENU_REQUEST_ERROR:
    
    return { ...state, menuData: action.payload,isMenuSuccess:false,isMenuError:true };

  case ACTION_TYPES.GET_ORDERS_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      orderData: {},
      isOrdersSuccess:false,
      isOrdersError:false 
    };

  case ACTION_TYPES.GET_ORDERS_REQUEST_SUCCESS:
    
    return { ...state, orderData: action.payload,isOrdersSuccess:true,isOrdersError:false };

  case ACTION_TYPES.GET_ORDERS_REQUEST_ERROR:
    
    return { ...state, orderData: action.payload,isOrdersSuccess:false,isOrdersError:true };

  case ACTION_TYPES.SET_ORDER_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      orderedData: {},
      isSetOrderSuccess:false,
      isSetOrderError:false 
    };
    
  case ACTION_TYPES.SET_ORDER_REQUEST_SUCCESS:
    
    return { ...state, orderedData: action.payload,isSetOrderSuccess:true,isSetOrderError:false };

  case ACTION_TYPES.SET_ORDER_REQUEST_ERROR:
    
    return { ...state, orderedData: action.payload,isSetOrderSuccess:false,isSetOrderError:true };


        

  case ACTION_TYPES.GET_ORDER_HISTORY_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      orderHistoryData: {},
      isOrderHistorySuccess:false,
      isOrderHistoryError:false 
    };
      
  case ACTION_TYPES.GET_ORDER_HISTORY_REQUEST_SUCCESS:
    
    return { ...state, orderHistoryData: action.payload.responses,isOrderHistorySuccess:true,isOrderHistoryError:false };

  case ACTION_TYPES.GET_ORDER_HISTORY_REQUEST_ERROR:
    
    return { ...state, orderHistoryData: action.payload,isOrderHistorySuccess:false,isOrderHistoryError:true };
      

  case ACTION_TYPES.SET_CART_COUNT:
    
    return { ...state, cartCount: action.payload };


  case ACTION_TYPES.ADD_TO_CART_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isCartAddedSuccess:false,
      isCartAddedError:false 
    };
              
  case ACTION_TYPES.ADD_TO_CART_REQUEST_SUCCESS:
    
    return { ...state, isCartAddedSuccess:true,isCartAddedError:false };

  case ACTION_TYPES.ADD_TO_CART_REQUEST_ERROR:
    
    return { ...state, isCartAddedSuccess:false,isCartAddedError:true };

  case ACTION_TYPES.UPDATE_CART_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      updatedcartItem: action.payload,
      isCartUpdatedSuccess:false,
      isCartUpdatedError:false 
    };

  case ACTION_TYPES.UPDATE_CART_REQUEST_SUCCESS:
    
    return { ...state, updatedcartItem: action.payload,isCartUpdatedSuccess:true,isCartUpdatedError:false };

  case ACTION_TYPES.UPDATE_CART_REQUEST_ERROR:
    
    return { ...state, updatedcartItem: action.payload,isCartUpdatedSuccess:false,isCartUpdatedError:true };


  case ACTION_TYPES.DELETE_CART_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isCartDeletedSuccess:false,
      isCartDeletedError:false 
    };

  case ACTION_TYPES.DELETE_CART_REQUEST_SUCCESS:
    
    return { ...state, isCartDeletedSuccess:true,isCartDeletedError:false };

  case ACTION_TYPES.DELETE_CART_REQUEST_ERROR:
    
    return { ...state, isCartDeletedSuccess:false,isCartDeletedError:true };


  case ACTION_TYPES.GET_CART_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isCartGetSuccess:false,
      isCartGetError:false,
      cartItem:{} 
    };
              
  case ACTION_TYPES.GET_CART_REQUEST_SUCCESS:
    
    return { ...state, cartItem:action.payload.cartList ,isCartGetSuccess:true,isCartGetError:false };

  case ACTION_TYPES.GET_CART_REQUEST_ERROR:
    
    return { ...state, cartItem:{} ,isCartGetSuccess:false,isCartGetError:true };
        

  case ACTION_TYPES.ADD_REVIEWS_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isReviewAddedSuccess:false,
      isReviewAddedError:false
    };
                
  case ACTION_TYPES.ADD_REVIEWS_REQUEST_SUCCESS:
    return { ...state,isReviewAddedSuccess:true,isReviewAddedError:false };

  case ACTION_TYPES.ADD_REVIEWS_REQUEST_ERROR:
    return { ...state,isReviewAddedSuccess:false,isReviewAddedError:true };



  case ACTION_TYPES.UPDATE_MENU_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isSubMenuSuccess: false,
      isSubMenuError: false,
    };
                
  case ACTION_TYPES.UPDATE_SUB_MENU_REQUEST_SUCCESS:
    return { ...state,isSubMenuSuccess:true,isSubMenuError:false };

  case ACTION_TYPES.UPDATE_SUB_MENU_REQUEST_ERROR:
    return { ...state,isSubMenuSuccess:false,isSubMenuError:true };
    

    
  case ACTION_TYPES.UPDATE_MENU_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isMenuUpdatedSuccess: false,
      isMenuUpdatedError: false,
    };
                
  case ACTION_TYPES.UPDATE_MENU_REQUEST_SUCCESS:
    return { ...state,isMenuUpdatedSuccess:true,isMenuUpdatedError:false };

  case ACTION_TYPES.UPDATE_MENU_REQUEST_ERROR:
    return { ...state,isMenuUpdatedSuccess:false,isMenuUpdatedError:true };

  case ACTION_TYPES.RESET_UPDATES_REQUEST: 
    return {...state, 
      isMenuUpdatedSuccess: false,
      isMenuUpdatedError: false };
      

  case ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isPayOrderOnlineSuccess: false,
      isPayOrderOnlineError: false,
    };
                
  case ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST_SUCCESS:
    return { ...state,isPayOrderOnlineSuccess:true,isPayOrderOnlineError:false };

  case ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST_ERROR:
    return { ...state,isPayOrderOnlineSuccess:false,isPayOrderOnlineError:true };


  case ACTION_TYPES.REPAY_CASH_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isRepayCashSuccess: false,
      isRepayCashError: false,
    };
                
  case ACTION_TYPES.REPAY_CASH_REQUEST_SUCCESS:
    return { ...state,isRepayCashSuccess:true,isRepayCashError:false };

  case ACTION_TYPES.REPAY_CASH_REQUEST_ERROR:
    return { ...state,isRepayCashSuccess:false,isRepayCashError:true };




    case ACTION_TYPES.REPAY_ONLINE_PAYTM_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isRepayOnlinePaytmSuccess: false,
      isRepayOnlinePaytmError: false,
    };
                
  case ACTION_TYPES.REPAY_ONLINE_PAYTM_REQUEST_SUCCESS:
    return { ...state,isRepayOnlinePaytmSuccess:true,repayonlineorderedData:action.payload,isRepayOnlinePaytmError:false };

  case ACTION_TYPES.REPAY_ONLINE_PAYTM_REQUEST_ERROR:
    return { ...state,isRepayOnlinePaytmSuccess:false,isRepayOnlinePaytmError:true };



  case ACTION_TYPES.PAY_ORDER_ONLINE_SODEXO_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isPayOrderOnlineSodexoSuccess: false,
      isPayOrderOnlineSodexoError: false,
    };
                
  case ACTION_TYPES.PAY_ORDER_ONLINE_SODEXO_REQUEST_SUCCESS:
    return { ...state,isPayOrderOnlineSodexoSuccess:true,isPayOrderOnlineSodexoError:false };

  case ACTION_TYPES.PAY_ORDER_ONLINE_SODEXO_REQUEST_ERROR:
    return { ...state,isPayOrderOnlineSodexoSuccess:false,isPayOrderOnlineSodexoError:true };


  case ACTION_TYPES.ADD_SODEXO_CARD_DETAILS_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isSodexoCardAddedSuccess: false,
      isSodexoCardAddedError: false

    };
                
  case ACTION_TYPES.ADD_SODEXO_CARD_DETAILS_REQUEST_SUCCESS:
    return { ...state,isSodexoCardAddedSuccess: true,
      isSodexoCardAddedError: false };

  case ACTION_TYPES.ADD_SODEXO_CARD_DETAILS_REQUEST_ERROR:
    return { ...state,isSodexoCardAddedSuccess: false,
      isSodexoCardAddedError: true };


  case ACTION_TYPES.DELETE_SODEXO_CARD_DETAILS_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 

    isSodexoCardDeletedSuccess: false,
    isSodexoCardDeletedError: false

    };
                
  case ACTION_TYPES.DELETE_SODEXO_CARD_DETAILS_REQUEST_SUCCESS:
    return { ...state, isSodexoCardDeletedSuccess: true,
    isSodexoCardDeletedError: false, };

  case ACTION_TYPES.DELETE_SODEXO_CARD_DETAILS_REQUEST_ERROR:
    return { ...state, isSodexoCardDeletedSuccess: false,
    isSodexoCardDeletedError: true };



    case ACTION_TYPES.GET_SODEXO_CARD_DETAILS_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
    isSodexoCardGetSuccess: false,
    isSodexoCardGetError: false

    };
                
  case ACTION_TYPES.GET_SODEXO_CARD_DETAILS_REQUEST_SUCCESS:
    return { ...state,isSodexoCardGetSuccess: true,
    isSodexoCardGetError: false,
    sodexoCardDetails:action.payload
   };

  case ACTION_TYPES.GET_SODEXO_CARD_DETAILS_REQUEST_ERROR:
    return { ...state,isSodexoCardGetSuccess: false,
    isSodexoCardGetError: true };




    
    
  case ACTION_TYPES.RESET_REQUEST: 
    return initialState;

    case ACTION_TYPES.RESET_PAY_ORDER_ONLINE_REQUEST:
    // here we catch our payload data and pass it into the state of the store
    return { ...state, 
      isPayOrderOnlineSuccess: false,
      isPayOrderOnlineError: false,
    };

    default:
      return state
  }
}